import { mapActions } from 'vuex';
import { $EventBus } from '@/main';

export default {
	name: 'UserPermissions',
	data: () => ({
		headers: [
			{
				text: 'Compañía',
				value: 'company_name',
				align: 'start',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Rol',
				value: 'profile_name',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Módulo',
				value: 'module_name',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Página',
				value: 'page_name',
				sortable: false,
				class: 'primary--text',
				align: 'start',
			},
			{
				text: 'Actividad',
				value: 'activity_name',
				sortable: false,
				class: 'primary--text',
			},
		],
	}),
	created() {
		this.getManagements({
			params: {
				user_id: this.$store.state.auth?.user?.id || null,
			},
		});
	},
	mounted: function () {
		$EventBus.$emit('showSubNav', true);
	},

	computed: {
		permissions() {
			let permissions = [];
			const companies = this.$store.state.auth.user?.companies || [];

			if (Array.isArray(companies)) {
				companies.forEach((company) => {
					company.profiles.forEach((profile) => {
						profile.modules.forEach((module) => {
							module.pages.forEach((page) => {
								page.activities.forEach((activity) => {
									permissions.push({
										company_name: company?.name,
										profile_name: profile?.role?.name,
										module_name: module?.name,
										page_name: page?.name,
										activity_name: activity?.name,
									});
								});
							});
						});
					});
				});
			}
			return permissions || [];
		},
		managements() {
			const managements = this.$store.state.security.managements;
			return Array.isArray(managements) ? [...managements] : [];
		},
	},
	watch: {},
	methods: {
		...mapActions('security', ['getManagements', 'cleanManagements']),
	},
	destroyed() {
		this.cleanManagements();
	},
	components: {},
};
