<template>
	<user-permissions />
</template>
<script>
import UserPermissions from '@/components/user/permissions/UserPermissions.vue';

export default {
	name: 'UserPermissionsPage',
	components: { UserPermissions },
};
</script>
